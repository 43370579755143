import {Component, Input, OnInit} from "@angular/core";
import {MenuService} from "../app.menu.service";
import {ColorScheme, LayoutService, MenuMode,} from "../service/app.layout.service";
import {changeColorScheme, changeTheme, replaceThemeLink} from "../../support/settings-functions";

@Component({
    selector: "app-config",
    templateUrl: "./app.config.component.html",
})
export class AppConfigComponent implements OnInit {
    @Input() minimal: boolean = false;

    componentThemes: any[] = [];

    layoutThemes: any[] = [];

    scales: number[] = [12, 13, 14, 15, 16];

    constructor(
        public layoutService: LayoutService,
        public menuService: MenuService
    ) {}

    get visible(): boolean {
        return this.layoutService.state.configSidebarVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.configSidebarVisible = _val;
    }

    get scale(): number {
        return this.layoutService.config.scale;
    }

    set scale(_val: number) {
        this.layoutService.config.scale = _val;
        localStorage.setItem('scale', String(this.scale));
    }

    get menuMode(): MenuMode {
        return this.layoutService.config.menuMode;
    }

    set menuMode(_val: MenuMode) {
        this.layoutService.config.menuMode = _val;
        if (
            this.layoutService.isSlimPlus() ||
            this.layoutService.isSlim() ||
            this.layoutService.isHorizontal()
        ) {
            this.menuService.reset();
        }
    }

    get colorScheme(): ColorScheme {
        return this.layoutService.config.colorScheme;
    }

    set colorScheme(_val: ColorScheme) {
        this.changeColorScheme(_val);
    }

    get ripple(): boolean {
        return this.layoutService.config.ripple;
    }

    set ripple(_val: boolean) {
        this.layoutService.config.ripple = _val;
    }

    ngOnInit() {
        this.componentThemes = [
            { name: "muni", color: "#383a67" },
            { name: "blue", color: "#0F8BFD" },
            { name: "green", color: "#0BD18A" },
            { name: "magenta", color: "#EC4DBC" },
            { name: "orange", color: "#FD9214" },
            { name: "purple", color: "#873EFE" },
            { name: "red", color: "#FC6161" },
            { name: "teal", color: "#00D0DE" },
            { name: "yellow", color: "#EEE500" },
        ];
    }

    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }

    changeColorScheme(colorScheme: ColorScheme) {
        changeColorScheme(colorScheme, this.layoutService);
    }

    changeTheme(theme: string) {
        localStorage.setItem('theme', theme);

        changeTheme(theme, this.layoutService);
        window.location.reload();
    }

    replaceThemeLink(href: string, onComplete: Function, linkId: string) {
        replaceThemeLink(href, onComplete, linkId);
    }

    decrementScale() {
        this.scale--;
        this.applyScale();
    }

    incrementScale() {
        this.scale++;
        this.applyScale();
    }

    applyScale() {
        document.documentElement.style.fontSize = this.scale + "px";
        localStorage.setItem('scale', String(this.scale));
    }

    changeMenuMode() {
        localStorage.setItem('menuMode', this.menuMode);
    }

    onChangeColorScheme() {
        localStorage.setItem('color-scheme', this.colorScheme);
    }
}
