import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {errorHandler} from "../../support/functions";

// Resource: http://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel

export const AUTO_COMPLETE_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => GenericCompleteComponent),
    multi: true
};

@Component({
    template: `
        <p-autoComplete [(ngModel)]="value" [disabled]="disabled"
                        placeholder="{{'placeholder.typeToSearch' | translate}}"
                        field="autocompleteDescription" [panelStyle]="panelStyle" [inputStyleClass]="inputStyleClass"
                        [appendTo]="appendTo" [suggestions]="suggestions" (completeMethod)="complete($event)"
                        (onSelect)="select($event)" [dropdown]="dropdown" [forceSelection]="true"
                        [styleClass]="styleClass">
            <ng-template let-item pTemplate="item">
                <ng-container *ngIf="isArray(displayValue); else elseBlock">
                    <ng-container *ngFor="let field of displayValue">
                        {{getField(item, field)}}
                    </ng-container>
                </ng-container>
                <ng-template #elseBlock>
                    {{item[displayValue]}}
                </ng-template>
            </ng-template>
        </p-autoComplete>
    `,
    selector: 'app-auto-complete',
    providers: [AUTO_COMPLETE_CONTROL_VALUE_ACCESSOR]
})
export class GenericCompleteComponent implements ControlValueAccessor {
    @Input() endPoint = 'complete'; // default endpoint: complete
    @Input() disabled = false;
    @Input() displayValue: string | string[];
    @Input() apiService: any;
    @Input() dropdown = false;
    @Input() appendTo;
    @Input() customComplete = false;
    @Input() panelStyle;
    @Input() inputStyleClass;
    @Input() styleClass;

    @Output() onSelectItem = new EventEmitter();
    @Output() customCompleteMethod = new EventEmitter();

    // tslint:disable-next-line:variable-name
    protected _value: any = null;
    public suggestions: any[] = [];

    // tslint:disable-next-line:variable-name
    private _onTouchedCallback: () => void = () => {
    };

    // tslint:disable-next-line:variable-name
    protected _onChangeCallback: (_: any) => void = () => {
    };

    constructor(private msgService: MessageService, private translate: TranslateService, private router: Router) {
    }

    /**
     * Workaround function for having multiple "description fields" in the autocomplete component
     * @param v the object  for which we're trying to give the multiple fields to display
     */
    public setValueDescription(v: any) {
        let description = '';
        if (v != null) {
            if (v instanceof Object && Array.isArray(this.displayValue)) {
                this.displayValue.forEach(field => {
                    const val = this.getField(v, field);
                    if (val != null) {
                        description = description.length === 0 ? val : `${description} ${val}`;
                    }
                });
                v.autocompleteDescription = description;
            } else if (v instanceof Object && !Array.isArray(this.displayValue)) {
                v.autocompleteDescription = this.getField(v, this.displayValue);
            }
        }
    }

    getField(item: any, field: string): string {
        const splitString = field.split('.');
        let data = item;
        for (const splitValue of splitString) {
            data = data ? data[splitValue] : '';
        }
        return data == null ? '' : String(data);
    }

    @Input()
    get value(): any {
        this.setValueDescription(this._value);
        this.suggestions.forEach(val => this.setValueDescription(val));
        return this._value;
    }

    // set accessor including call the onchange callback
    set value(v: any) {
        if (this._value != null && (v == null || v === '')) {
            this.select(null);
        }
        // nop, see writeValue and select method
    }

    // Set touched on blur
    onTouched() {
        this._onTouchedCallback();
    }

    // From ControlValueAccessor interface
    writeValue(value: any) {
        this._value = value;
    }

    // From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this._onChangeCallback = fn;
    }

    // From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this._onTouchedCallback = fn;
    }

    // From ControlValueAccessor interface
    setDisabledState(isDisabled: boolean) {
    }

    complete(event: any) {
        if (this.customComplete) {
            this.customCompleteMethod.emit(event);
            return;
        }

        this.apiService[this.endPoint](event.query).subscribe(results => this.suggestions = results,
            error => errorHandler('Error during auto-complete', error, this.msgService, this.translate, this.router)
        );
    }

    select(v: any) {
        this._value = v;
        this._onChangeCallback(v);
        this.onSelectItem.emit(v);
    }

    public isArray(value: any) {
        return Array.isArray(value);
    }
}
