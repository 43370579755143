<form (keydown.enter)="$event.preventDefault()"  [formGroup]="form" *ngIf="form">
    <div id="div-container">
        <p-galleria [value]="images" [responsiveOptions]="responsiveOptions" containerClass="galleryBorder"
                    [containerStyle]="{'padding': imagePadding}"
                    [showThumbnails]="false" [autoPlay]="true"
                    [numVisible]="3" [circular]="true" [showIndicators]="true" [showItemNavigators]="true">
            <ng-template pTemplate="item" let-item>
                <img id="img-class" alt="" [src]="getImage(item)" style="max-height: 300px; max-width:100%;"/>
            </ng-template>
        </p-galleria>
    </div>
</form>
