import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {SharedModule as PrimeSharedModule} from "primeng/api";
import {GenericCompleteComponent} from "./generic-auto-complete.component";
import {AutoCompleteModule} from "primeng/autocomplete";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {ImageGalleryComponent} from "./image-gallery.component";
import {GalleriaModule} from "primeng/galleria";
import {UploadComponent} from "./upload.component";
import {FileUploadModule} from "primeng/fileupload";
import {GenericFormCompleteComponent} from "./generic-form-auto-complete.component";
import {LocationMapDialogComponent} from "../location/location-map.dialog.component";
import {RippleModule} from "primeng/ripple";
import {InputTextareaModule} from "primeng/inputtextarea";
import {GenericDetailComponent} from "./generic-detail.component";
import {MapComponent} from "../location/map.component";
import {UploadImageComponent} from "./upload-image.component";
import {PanelModule} from "primeng/panel";
import {TableModule} from "primeng/table";
import {RouterLink} from "@angular/router";


@NgModule({
    imports: [CommonModule, PrimeSharedModule, AutoCompleteModule, FormsModule, TranslateModule, GalleriaModule,
        ReactiveFormsModule, FileUploadModule, RippleModule, InputTextareaModule, PanelModule, TableModule, RouterLink],
    providers: [],
    declarations: [
        GenericCompleteComponent, GenericFormCompleteComponent, GenericDetailComponent, ImageGalleryComponent,
        UploadComponent, LocationMapDialogComponent, MapComponent, UploadImageComponent
    ],
    exports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, GenericCompleteComponent, ImageGalleryComponent,
        UploadComponent, GenericFormCompleteComponent, LocationMapDialogComponent, MapComponent, UploadImageComponent]
})
export class SharedModule {
}
