import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { MapComponent } from './map.component';  // Update this path to your actual path

@Component({
    selector: 'app-location-map-dialog',
    template: `
        <app-map #mapComponent></app-map>
        <button (click)="placeMarker()">Place Marker</button>
    `
})
export class LocationMapDialogComponent implements AfterViewInit {
    @ViewChild('mapComponent', { static: false }) mapComponent!: MapComponent;

    ngAfterViewInit() {
        // Optional: Place an initial marker
        this.placeMarker();
    }

    placeMarker() {
        const address = 'Ilindenska 53, Star Dojran, Makedonija';  // Replace with your address
        this.mapComponent?.placeMarker(address);
    }
}
