import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {GenericApiService} from "../components/generics/generic-api.service";

@Injectable()
export class FileService extends GenericApiService<any> {
    serviceUrl = '/komuniti-api/files'

    constructor(protected http: HttpClient) {
        super(http);
    }

    public uploadImage(formData: FormData): Observable<any> {
        return this.http.post<any>(`${this.serviceUrl}/image/upload/multiple`, formData, {
            reportProgress: true,
            observe: 'events'
        })
            .pipe(map(res => this.parse(res)));
    }

    public downloadImage(imageUrl: string): any {
        return this.http.get(`${this.serviceUrl.replace('/files', '')}/${imageUrl}`, {observe: 'response', responseType: 'blob'})
            .pipe(map(response => response.body));
    }

    public deleteImage(imageId: string): Observable<void> {
        return this.http.delete<any>(`${this.serviceUrl}/image/delete/${imageId}`)
            .pipe(map(res => this.parse(res)));
    }
}
