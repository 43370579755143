<router-outlet></router-outlet>

<p-toast>
    <ng-template let-message pTemplate="message">
        <span [class]="'p-toast-message-icon pi' + (message.icon ? ' ' + message.icon : '')"
              [ngClass]="{'pi-info-circle': message.severity == 'info', 'pi-exclamation-triangle': message.severity == 'warn',
                        'pi-times-circle': message.severity == 'error', 'pi-check' :message.severity == 'success'}"></span>
        <div class="p-toast-message-text">
            <div class="p-toast-summary">{{message.summary}}</div>
            <div class="p-toast-detail"><p innerHtml="{{message.detail}}"></p></div>
        </div>
    </ng-template>
</p-toast>
