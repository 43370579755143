import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {ChatAppRoutingModule} from './chat.app-routing.module';
import {ChatAppComponent} from './chat.app.component';
import {AvatarModule} from 'primeng/avatar';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {BadgeModule} from 'primeng/badge';
import {ChatBoxComponent} from './chat-box.component';
import {RippleModule} from 'primeng/ripple';
import {ChatService} from "./chat.service";
import {TagModule} from "primeng/tag";

@NgModule({
    imports: [CommonModule, FormsModule, ChatAppRoutingModule, AvatarModule, InputTextModule, ButtonModule, BadgeModule, OverlayPanelModule, RippleModule, TagModule],
    declarations: [ChatBoxComponent, ChatAppComponent],
    providers: [ChatService]
})
export class ChatAppModule {
}
