import {Component, forwardRef, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR, UntypedFormGroup} from '@angular/forms';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {GenericCompleteComponent} from './generic-auto-complete.component';

// Resource: http://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel

export const AUTO_COMPLETE_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => GenericFormCompleteComponent),
    multi: true
};

@Component({
    template: `
        <form (keydown.enter)="$event.preventDefault()" [formGroup]="form">
            <p-autoComplete [formControlName]="formControlName" placeholder="{{'placeholder.typeToSearch' | translate}}"
                            [field]="field" [panelStyle]="panelStyle" [inputStyleClass]="inputStyleClass"
                            (ngModelChange)="onModelChange($event)" [styleClass]="styleClass"
                            [appendTo]="appendTo" [suggestions]="suggestions" (completeMethod)="complete($event)"
                            (onSelect)="select($event)" [dropdown]="dropdown" [forceSelection]="true">
                <ng-template let-item pTemplate="item">
                    <ng-container *ngIf="isArray(displayValue); else elseBlock">
                        <ng-container *ngFor="let field of displayValue">
                            {{getField(item, field)}}
                        </ng-container>
                    </ng-container>
                    <ng-template #elseBlock>
                        {{item[displayValue]}}
                    </ng-template>
                </ng-template>
            </p-autoComplete>
        </form>
    `,
    selector: 'app-form-auto-complete',
    providers: [AUTO_COMPLETE_CONTROL_VALUE_ACCESSOR]
})
export class GenericFormCompleteComponent extends GenericCompleteComponent {
    @Input() form: UntypedFormGroup;
    @Input() formControlName: string;
    @Input() field = 'autocompleteDescription';

    constructor(messageService: MessageService, translate: TranslateService, router: Router) {
        super(messageService, translate, router);
    }

    select(v: any) {
        this.setValueDescription(v);
        this._value = v;
        this.form.controls[this.formControlName].setValue(v);
        this._onChangeCallback(v);
        this.onSelectItem.emit(v);
    }

    onModelChange(v: any) {
        const acDescription = this.form.controls[this.formControlName].value?.autocompleteDescription;
        if (!acDescription && acDescription !== '') {
            this.setValueDescription(v);
            this.form.controls[this.formControlName].setValue(v);
        }
    }
}
