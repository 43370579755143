export interface ChatMessage {
    topic: string;
    sender: string;
    content: string;
    timestamp: Date;
    type: ChatType;
    isAdmin?: boolean;
}

export enum ChatType {
    JOIN = 'JOIN',
    LEAVE = 'LEAVE',
    CHAT = 'CHAT',
    TOPICS = 'TOPICS'
}
