import {Component, Inject} from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {confirmAction} from "../../support/functions";
import {LocationStrategy} from "@angular/common";
import {firstValueFrom} from "rxjs";
import {FormGroup} from "@angular/forms";

@Component({
    template: ``,
    selector: 'app-generic-detail',
})
export class GenericDetailComponent {
    paramsId: any;
    saveButtonLoading = false;
    today = new Date();

    constructor(protected confirmationService: ConfirmationService, protected translate: TranslateService,
                protected msgService: MessageService, protected location: LocationStrategy,
                @Inject(String) protected apiService: any) {
    }

    successConfirmation(): void {
        this.confirmationService.confirm({
            message: this.translate.instant('msg.successfullySaved'),
            rejectVisible: false,
            closeOnEscape: false,
            acceptLabel: 'OK',
            icon: 'pi pi-exclamation-triangle'
        });
    }

    processValue(data: any, form: FormGroup) {
        form.patchValue(data);
        this.successConfirmation();
        this.saveButtonLoading = false;
    }

    async confirmClose() {
        const confirm = await confirmAction(this.confirmationService, this.translate);
        if (confirm) {
            this.location.back();
        }
    }

    handleError(error: any) {
        this.msgService.add({
            life: 7000, severity: 'error', summary: this.translate.instant('error.error'),
            detail: this.translate.instant('error.' + error.error?.message)
        });
        this.saveButtonLoading = false;
    }

    submit(form: FormGroup): Promise<boolean> {
        this.saveButtonLoading = true;

        if (!this.paramsId || this.paramsId === 'new') {
            return firstValueFrom(this.apiService.create(form.getRawValue())).then(data => {
                    this.processValue(data, form);
                    this.paramsId = form.controls.id.value;
                    return true;
                },
                error => {
                    this.handleError(error);
                    return false;
                });
        } else {
            return firstValueFrom(this.apiService.update(form.getRawValue())).then(data => {
                    this.processValue(data, form);
                    return true;
                },
                error => {
                    this.handleError(error);
                    return false;
                });
        }
    }
}
