import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { AppSidebarComponent } from './app.sidebar.component';
import {AuthService} from "../support/auth.service";
import {ChatAppComponent} from "../components/chat/chat.app.component";
import {DialogService} from "primeng/dynamicdialog";
import {LanguageService} from 'src/app/components/generics/language.service';
import { ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent implements OnInit{

    @ViewChild('menubutton') menuButton!: ElementRef;
    @ViewChild('searchinput') searchInput!: ElementRef;
    @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;
    searchActive: boolean = false;
    constructor(public layoutService: LayoutService,
                private router: Router,
                public el: ElementRef,
                public languageService: LanguageService,
                private cdr: ChangeDetectorRef,
                public translate: TranslateService,
                public authService: AuthService,
                private dialogService: DialogService) { }
    activateSearch() {
        this.searchActive = true;
        setTimeout(() => {
            this.searchInput.nativeElement.focus();
        }, 100);
    }

    ngOnInit(): void {
        const savedLanguageCode = localStorage.getItem('language');
        if (savedLanguageCode) {
            const savedLanguage = this.languages.find(lang => lang.code === savedLanguageCode);
            if (savedLanguage) {
                this.selectedLanguage = savedLanguage;
            }
        }
    }
    navigateToEditUser() {
        this.router.navigate( ['/user/edit-account']);
    }



    navigateToDeleteUser() {
        this.router.navigate( ['/user/delete-account']);
    }
    deactivateSearch() {
        this.searchActive = false;
    }
    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }

    onSidebarButtonClick() {
        this.layoutService.showSidebar();
    }

    onChat() {
        this.dialogService.open(ChatAppComponent, {width: '1200px'})
    }

    languages = [
        {label: 'EN', code: 'en', icon: '/assets/opop/EN_SQUARE.png'},
        {label: 'MK', code: 'mk', icon: '/assets/opop/MK_SQUARE.png'},
        {label: 'AL', code: 'al', icon: '/assets/opop/AL_SQUARE.png'}
    ];
    selectedLanguage = this.languages[0];
    changeLanguageOld(language: string): void {
        this.translate.use(language);
        this.languageService.setSelectedLanguage(this.selectedLanguage);
        this.cdr.detectChanges();
    }
    changeLanguage(languageCode: string): void {
        const newLanguage = this.languages.find(lang => lang.code === languageCode);

        if (newLanguage) {
            this.selectedLanguage = newLanguage; // Update the selected language
        }
        this.translate.use(languageCode);
        this.languageService.setSelectedLanguage(this.selectedLanguage);
        this.cdr.detectChanges();
    }

}
