import {ColorScheme, LayoutService} from "../layout/service/app.layout.service";

export function replaceThemeLink(href: string, onComplete: Function, linkId: string) {
    const id = linkId;
    const themeLink = <HTMLLinkElement>document.getElementById(id);
    const cloneLinkElement = <HTMLLinkElement>themeLink.cloneNode(true);

    cloneLinkElement.setAttribute("href", href);
    cloneLinkElement.setAttribute("id", id + "-clone");

    themeLink.parentNode!.insertBefore(
        cloneLinkElement,
        themeLink.nextSibling
    );

    cloneLinkElement.addEventListener("load", () => {
        themeLink.remove();
        cloneLinkElement.setAttribute("id", id);
        onComplete();
    });
}

export function changeTheme(theme: string, layoutService: LayoutService) {
    const themeLink = <HTMLLinkElement>(
        document.getElementById("theme-link")
    );

    const newHref = themeLink
        .getAttribute("href")!
        .replace(layoutService.config.theme, theme);
    replaceThemeLink(
        newHref,
        () => {
            layoutService.config.theme = theme;
            layoutService.onConfigUpdate();
        },
        "theme-link"
    );
}

export function changeColorScheme(colorScheme: ColorScheme, layoutService: LayoutService) {
    const themeLink = <HTMLLinkElement>(
        document.getElementById("theme-link")
    );
    const themeLinkHref = themeLink.getAttribute("href");
    const currentColorScheme =
        "theme-" + layoutService.config.colorScheme;
    const newColorScheme = "theme-" + colorScheme;
    const newHref = themeLinkHref!.replace(
        currentColorScheme,
        newColorScheme
    );
    replaceThemeLink(
        newHref,
        () => {
            layoutService.config.colorScheme = colorScheme;
            layoutService.onConfigUpdate();
        },
        "theme-link"
    );
}

export function applyScale(scale: number){
    document.documentElement.style.fontSize = scale + "px";
}
