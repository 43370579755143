import {Injectable} from '@angular/core';


@Injectable({providedIn: 'root'})
export class StorageService {
    map: Map<string, any>

    constructor() {
        this.map = new Map();
    }

    setValue(key: string, value: any): void {
        this.map.set(key, value);
    }

    getValue(key: string): any {
        return this.map.get(key);
    }

    removeValue(key: string): void {
        this.map.delete(key);
    }
}
