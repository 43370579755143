import {TranslateService} from "@ngx-translate/core";
import {Injector} from "@angular/core";
import {ColorScheme, LayoutService, MenuMode} from "../layout/service/app.layout.service";
import {LOCATION_INITIALIZED} from "@angular/common";
import {applyScale, changeColorScheme, changeTheme} from "./settings-functions";
import {StorageService} from "./storage.service";
import {firstValueFrom} from "rxjs";
import {AuthService} from "./auth.service";
import {environment} from "../../environments/environment";

export function appInitializerFactory(translate: TranslateService, injector: Injector, layoutService: LayoutService,
                                      authService: AuthService, storageService: StorageService) {
    return () => new Promise<any>((resolve: any) => {
            const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
            locationInitialized.then(() => {
                    const langToSet = localStorage.getItem('language') ?? 'en';
                    translate.setDefaultLang(langToSet);

                    translate.use(langToSet).subscribe({
                        next: () => console.log(`Successfully initialized '${langToSet}' language.'`),
                        error: () => {
                            console.error(`Problem with '${langToSet}' language initialization.'`);
                            resolve(null);
                        },
                        complete: () => resolve(null)
                    });

                    localStorage.setItem('theme', localStorage.getItem('theme') ?? 'muni');
                    localStorage.setItem('menuMode', localStorage.getItem('menuMode') ?? 'overlay');
                    changeTheme(localStorage.getItem('theme'), layoutService);
                    changeColorScheme(<ColorScheme>localStorage.getItem('color-scheme') ?? 'dark', layoutService);
                    layoutService.config.theme = localStorage.getItem('theme');
                    layoutService.config.colorScheme = <ColorScheme>localStorage.getItem('color-scheme');
                    layoutService.config.menuMode = <MenuMode>localStorage.getItem('menuMode');

                    const scale = localStorage.getItem('scale');
                    layoutService.config.scale = isNaN(parseInt(scale)) ? 14 : parseInt(scale);
                    applyScale(parseInt(localStorage.getItem('scale')));

                    // Initialize logged user data
                    if (localStorage.getItem(environment.LOCAL_STORAGE_TOKEN)) {
                        firstValueFrom(authService.fetchUserDetails())
                            .then(userDetails => {
                                    storageService.setValue(environment.USER_DETAILS, userDetails);
                                },
                                () => localStorage.removeItem(environment.LOCAL_STORAGE_TOKEN)

                            );
                    }
                }
            );
        }
    );
}
