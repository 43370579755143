import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z?(([+\-])\d{2}:\d{2})?$/;

@Injectable()
export class GenericApiService<T> {
    public serviceUrl: string;

    constructor(protected http: HttpClient) {
    }

    // tslint:disable-next-line:no-shadowed-variable
    public parse<T>(object: any): T {
        return JSON.parse(JSON.stringify(object), this.reviver) as T;
    }

    // https://mariusschulz.com/blog/deserializing-json-strings-as-javascript-date-objects
    public reviver(key, value) {
        if (typeof value === 'string' && dateFormat.test(value)) {
            return new Date(value);
        }
        return value;
    }
}
