
<div class="back-box">
    <!--    <div *ngIf="message" [class.success]="isSuccess" [class.error]="!isSuccess"> {{ message }} </div>-->

    <form #emailFormID [formGroup]="emailForm">

        <div class="field">
            <label for="replyTo" class="eMail-formLabel">{{'mailForm.writerMail'|translate}}</label>
            <input id="replyTo" class="w-full" pInputText type="text" formControlName="replyTo"
                   style="overflow-y: auto; resize: vertical; border: 1px solid #9999; color: black; ">
        </div>

        <div class="field">
            <label for="subject" class="eMail-formLabel">{{'mailForm.subject'|translate}}</label>
            <textarea id="subject" class="w-full" rows="2" pInputText formControlName="subject"
                      style="overflow-y: auto; resize: vertical; border: 1px solid #9999; color: black; "></textarea>
        </div>

        <div class="field">
            <label for="message" class="eMail-formLabel">{{'mailForm.message'|translate}}</label>
            <textarea id="message" class="w-full" rows="8" pInputText formControlName="message"
                      style="overflow-y: auto; resize: vertical; border: 1px solid #999; color: black; "></textarea>
        </div>

        <!--        <div class="field">-->
        <!--            <label for="email" class="text-lg">Mail will be send to:</label>-->
        <!--            <input id="email" class="w-full" pInputText type="text" formControlName="email">-->
        <!--        </div>-->

        <!--        <div class="field">-->
        <!--            <label for="cc" class="text-lg">cc will be made to this address</label>-->
        <!--            <input id="cc" class="w-full" pInputText type="text" formControlName="cc">-->
        <!--        </div>-->

        <!--        <div class="field">-->
        <!--            <label for="bcc" class="text-lg">bcc will be made to this address</label>-->
        <!--            <input id="bcc" class="w-full" pInputText type="text" formControlName="bcc">-->
        <!--        </div>-->

        <div class="button-container">
            <button type="button" class="mt-1 p-button-danger" pButton icon="pi pi-times-circle"
                    (click)="close()" label="{{'mailForm.close'|translate}}">
            </button>

            <button type="button" class="mt-1" pButton icon="pi pi-send"
                    (click)="submit()" label="{{'mailForm.send'|translate}}">
            </button>
        </div>
    </form>


</div>

