import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {AppLayoutComponent} from './layout/app.layout.component';
import {loginGuard} from "./support/auth-guard";
import {MailOutComponent} from './components/mail-out/mail-out.component';

const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled'
};

const routes: Routes = [
    {
        path: '', component: AppLayoutComponent,
        children: [
            {
                path: '', redirectTo: 'article/article-search', pathMatch: 'full'
            },
            {
                path: 'article', loadChildren: () => import('./components/article/article.module')
                    .then(m => m.ArticleModule)
            },
            {
                path: 'complaint', loadChildren: () => import('./components/complaint/complaint.module')
                    .then(m => m.ComplaintModule)
            },
            {
                path: 'alert', loadChildren: () => import('./components/alert/alert.module')
                    .then(m => m.AlertModule)
            },
            {
                path: 'budget', loadChildren: () => import('./components/budget/budget.module')
                    .then(m => m.BudgetModule)
            },
            {
                path: 'user', loadChildren: () => import('./components/user/user.module')
                    .then(m => m.UserModule)
            },
            {
                path: 'report', loadChildren: () => import('./components/report/report.module')
                    .then(m => m.ReportModule)
            }
            // {
            //     path: 'event', loadChildren: () => import('./components/event/event.module')
            //         .then(m => m.EventModule)
            // },
            // {
            //     path: 'information', loadChildren: () => import('./components/information/information.module')
            //         .then(m => m.InformationModule)
            // }

        ]
    },
    {
        path: 'article/share/:id', loadChildren: () => import('./components/shared-article/shared-article.module')
            .then(m => m.SharedArticleModule)
    },
    {
        path: 'auth',
        data: {breadcrumb: 'Auth'},
        loadChildren: () => import('./components/auth/auth.module').then(m => m.AuthModule),
        canActivate: [loginGuard]
    },
    {
        path: 'send-email',
        component: MailOutComponent,
    },
    {path: 'notfound', loadChildren: () => import('./components/notfound/notfound.module').then(m => m.NotfoundModule)},
    {path: '**', redirectTo: '/notfound'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
