import {TranslateService} from "@ngx-translate/core";

export enum Role {
    SUPER_ADMIN = "SUPER_ADMIN",
    MUNICIPALITY_ADMIN = "MUNICIPALITY_ADMIN",
    ARTICLE_ADMINISTRATOR = "ARTICLE_ADMINISTRATOR",
    ADMIN_MODERATOR = "ADMIN_MODERATOR",
    COMPLAINT_ADMINISTRATOR = "COMPLAINT_ADMINISTRATOR",
    ALERT_ADMINISTRATOR = "ALERT_ADMINISTRATOR",
    USER = "USER"
}

export function role_items(translate: TranslateService) {
    return [
        {label: translate.instant('enum.Role.SUPER_ADMIN'), value: Role.SUPER_ADMIN},
        {label: translate.instant('enum.Role.MUNICIPALITY_ADMIN'), value: Role.MUNICIPALITY_ADMIN},
        {label: translate.instant('enum.Role.ARTICLE_ADMINISTRATOR'), value: Role.ARTICLE_ADMINISTRATOR},
        {label: translate.instant('enum.Role.ADMIN_MODERATOR'), value: Role.ADMIN_MODERATOR},
        {label: translate.instant('enum.Role.COMPLAINT_ADMINISTRATOR'), value: Role.COMPLAINT_ADMINISTRATOR},
        {label: translate.instant('enum.Role.ALERT_ADMINISTRATOR'), value: Role.ALERT_ADMINISTRATOR},
        {label: translate.instant('enum.Role.USER'), value: Role.USER}
    ];
}

export function superAdminRole_items(translate: TranslateService) {
    return [
        {label: translate.instant('enum.Role.MUNICIPALITY_ADMIN'), value: Role.MUNICIPALITY_ADMIN},
        {label: translate.instant('enum.Role.ARTICLE_ADMINISTRATOR'), value: Role.ARTICLE_ADMINISTRATOR},
        {label: translate.instant('enum.Role.ADMIN_MODERATOR'), value: Role.ADMIN_MODERATOR},
        {label: translate.instant('enum.Role.COMPLAINT_ADMINISTRATOR'), value: Role.COMPLAINT_ADMINISTRATOR},
        {label: translate.instant('enum.Role.ALERT_ADMINISTRATOR'), value: Role.ALERT_ADMINISTRATOR},
        {label: translate.instant('enum.Role.USER'), value: Role.USER}
    ];
}

export function municipalityAdminRole_items(translate: TranslateService) {
    return [
        {label: translate.instant('enum.Role.MUNICIPALITY_ADMIN'), value: Role.MUNICIPALITY_ADMIN},
        {label: translate.instant('enum.Role.ARTICLE_ADMINISTRATOR'), value: Role.ARTICLE_ADMINISTRATOR},
        {label: translate.instant('enum.Role.ADMIN_MODERATOR'), value: Role.ADMIN_MODERATOR},
        {label: translate.instant('enum.Role.COMPLAINT_ADMINISTRATOR'), value: Role.COMPLAINT_ADMINISTRATOR},
        {label: translate.instant('enum.Role.ALERT_ADMINISTRATOR'), value: Role.ALERT_ADMINISTRATOR},
        {label: translate.instant('enum.Role.USER'), value: Role.USER}
    ];
}
