import {Injectable} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface Language {
    label: string;
    code: string;
    icon: string;
}

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    private languageSubject: BehaviorSubject<Language> = new BehaviorSubject<Language>({
        label: 'EN',
        code: 'en',
        icon: 'flag-icon-en'
    });

    constructor() {
        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage) {
            const languageObj = {
                label: savedLanguage.toUpperCase(),
                code: savedLanguage,
                icon: `flag-icon-${savedLanguage}`
            };
            this.languageSubject.next(languageObj);
            this.currentLang = languageObj;
        }
    }

    private currentLang: Language = {
        label: 'EN',
        code: 'en',
        icon: 'flag-icon-en'
    };

    setSelectedLanguage(lang: Language) {
        this.languageSubject.next(lang);
        this.currentLang = lang;
        localStorage.setItem('language', lang.code);
    }

    getSelectedLanguage(): Observable<Language> {
        return this.languageSubject.asObservable();
    }
    getCurrentLanguage(): Language {
        return this.currentLang;
    }
}
