import {Router} from "@angular/router";
import {ConfirmationService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";

export function errorHandler(summary: string, error: any, messageService, translate, router: Router) {
    if (Number(error?.status) === 401) {
        router.navigate(['/login']);
    }
    messageService.add({
        severity: 'error', life: 6000,
        summary: translate.instant(summary),
        detail: error.message
    });
}

export function confirmAction(confirmationService: ConfirmationService, translate: TranslateService): Promise<boolean> {
    return new Promise(resolve => {
        confirmationService.confirm({
            key: 'confirmPopup',
            target: event.target,
            message: translate.instant('dialog.areYouSure'),
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: translate.instant('btn.yes'),
            rejectLabel: translate.instant('btn.no'),
            accept: () => resolve(true),
            reject: () => resolve(false)
        })
    });
}

export function parseJson(str: string): any {
    try {
        return JSON.parse(str);
    } catch (e) {
        return '';
    }
}

export function compareDatesWithoutTime(date1: Date, date2: Date): number {
    const normalizedDate1 = new Date(date1);
    normalizedDate1.setHours(0, 0, 0, 0);

    const normalizedDate2 = new Date(date2);
    normalizedDate2.setHours(0, 0, 0, 0);

    return normalizedDate1.getTime() - normalizedDate2.getTime();
}
