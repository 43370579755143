import {UntypedFormGroup} from "@angular/forms";
import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {FileUpload} from "primeng/fileupload";
import {ConfirmationService, MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {confirmAction, errorHandler} from "../../support/functions";
import {firstValueFrom} from "rxjs";
import {Router} from "@angular/router";
import {FileService} from "../../support/file.service";
import {Image} from "../../model/image";

@Component({
    template: `
        <p-fileUpload #fileUploader name="file[]" (onUpload)="handleFileUpload($event)" [hidden]="true"
                      [customUpload]="true" [accept]="'image/*'"
                      (onSelect)="handleFileUpload($event)" [showUploadButton]="false"
                      [showCancelButton]="false" [auto]="true" class="w-full">
        </p-fileUpload>

        <p-panel styleClass="mt-2 mb-2 {{hasDocs}} noPanelPadding">
            <ng-template pTemplate="header">
                <div style="width:22rem; cursor:copy;" (click)="fileUploader.advancedFileInput.nativeElement.click()">
                    <i class="pi pi-upload text-xl text-white"></i>
                    <span class="text-white text-lg ml-3">{{'btn.addImage'|translate}}</span>
                </div>
            </ng-template>

            <div class="text-center" *ngIf="form.controls.images.value?.length === 0">
                <div class="flex align-items-center justify-content-center">
                    <img [src]="'/assets/muni/no-img.png'" [alt]="" style="max-width: 100%;"/>
                </div>
            </div>

            <a [routerLink]="'/img-view/' + form.controls.imageUrl.value" target="_blank">
                <div class="muni-image-container cursor-pointer" *ngIf="form.controls.images.value?.length > 0">
                    <img style="height:auto"
                         [src]="'/komuniti-api/' + form.controls.imageUrl.value" alt="Image"/>
                </div>
            </a>


            <div class="h-6rem">
                <p-table [value]="form.controls.images.value" [scrollable]="true" [rows]="3"
                         scrollHeight="90px"
                         styleClass="p-datatable-sm p-datatable-gridlines h-4rem">
                    <ng-template pTemplate="header">
                        <tr>
                            <th colspan="3" style="border-top: 0; border-left:0; border-right: 0"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-img>
                        <tr>
                            <td class="w-3rem">
                                <i class="pi pi-bookmark-fill" *ngIf="img.imageUrl === form.controls.imageUrl.value"></i>
                                <i class="pi pi-bookmark" *ngIf="img.imageUrl !== form.controls.imageUrl.value"
                                   (click)="makeMainImage(img)"></i>
                            </td>
                            <td style="width: 75%">{{ img.fileName }}</td>
                            <td class="text-right" style="min-width: 80px">
                                <i class="icon-hover cursor-pointer pi pi-trash text-white-400 mr-2" (click)="confirmDelete(img)"></i>
                                <i class="icon-hover cursor-pointer pi pi-download text-white-400"
                                   (click)="downloadDocument(img.imageUrl, img.fileName)"></i>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="7">{{'entity.article.noImages'|translate}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </p-panel>


    `,
    selector: 'app-upload-image',
    styles: [`
        .p-fileupload-choose {
            display: none !important;
        }

        .p-fileupload .p-fileupload-buttonbar {
            border: 0;
        }

        .p-fileupload .p-fileupload-content {
            -moz-border-radius: 8px;
            -webkit-border-radius: 8px;
            padding: 0
        }
    `],
    encapsulation: ViewEncapsulation.None
})

export class UploadImageComponent implements OnInit {
    @Input()
    public form: UntypedFormGroup;
    @ViewChild('fileUploader') fileUpload: FileUpload;

    constructor(private fileService: FileService, private msgService: MessageService, private translate: TranslateService,
                private confirmationService: ConfirmationService, private router: Router) {
    }

    ngOnInit(): void {
        this.setDefaultSelectedImage();
    }

    handleFileUpload(event: any) {
        const formData = new FormData();
        for (let file of event.files) {
            formData.append('files', file, file.name);
        }

        this.fileService.uploadImage(formData).subscribe({
            next: response => {
                if (response.body) {
                    this.msgService.add({
                        severity: 'info', life: 7000,
                        detail: this.translate.instant('msg.upload-success')
                    });
                    response.body.images.forEach(img => this.form.controls.images.value.push(img));

                    this.fileUpload.clear();
                    this.setDefaultSelectedImage();
                }
            }, error: error => {
                this.msgService.add({
                    severity: 'error',
                    summary: this.translate.instant('error.header'),
                    detail: error.message
                });
            }
        });
    }

    async confirmDelete(img: Image) {
        const confirm = await confirmAction(this.confirmationService, this.translate);
        if (confirm) {
            this.deleteImage(img);
        }
    }

    downloadDocument(imageUrl: string, fileName: string) {
        firstValueFrom(this.fileService.downloadImage(imageUrl)).then((blob: Blob) => {
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = fileName;
            downloadLink.click();
            URL.revokeObjectURL(downloadLink.href);
            downloadLink.remove();
        });
    }

    deleteImage(img: Image) {
        firstValueFrom(this.fileService.deleteImage(img.id)).then(() => {
            const indexToDelete = this.form.controls.images.value.indexOf(img);
            this.form.controls.images.value.splice(indexToDelete, 1);

            this.msgService.add({
                severity: 'info',
                summary: 'Info',
                detail: this.translate.instant('msg.fileDeleteSuccess')
            });
        }, error => errorHandler('Error', error, this.msgService, this.translate, this.router))
    }

    get hasDocs(): string {
        return this.form.controls.images.value?.length > 0 ? 'muniUpload-docs' : 'muniUpload-noDocs';
    }

    setDefaultSelectedImage() {
        if (!this.form.controls.imageUrl.value && this.form.controls.images.value?.length > 0) {
            this.form.controls.imageUrl.setValue(this.form.controls.images.value[0].imageUrl);
            this.form.controls.thumbUrl.setValue(this.form.controls.images.value[0].thumbUrl);
        }
    }

    makeMainImage(image: Image) {
        this.form.controls.imageUrl.setValue(image.imageUrl);
        this.form.controls.thumbUrl.setValue(image.thumbUrl);

        this.msgService.add({
            severity: 'info',
            summary: 'Info',
            detail: this.translate.instant('msg.mainImageChanged')
        });
    }
}
