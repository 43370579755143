import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {TranslateModule} from "@ngx-translate/core";
import {TermsFAQComponent} from './terms-faq.component';
import {TermsAndConditionsComponent} from './terms-conditions.component';
import {SharedModule} from "../generics/shared.module";


@NgModule({
    imports: [SharedModule,TranslateModule,CommonModule,ButtonModule,InputTextModule],
    declarations: [ TermsFAQComponent, TermsAndConditionsComponent ],
    exports: [ TermsFAQComponent, TermsAndConditionsComponent ]
})

export class FooterModule {
}
