import { Component } from '@angular/core';
import {FormGroup, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {EmailService} from "../../support/email.service";  // Import your email service here
import {Router} from "@angular/router";
import {Input} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute } from '@angular/router';
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import { DynamicDialogRef } from 'primeng/dynamicdialog';



@Component({
    selector: 'app-mail-out',
    templateUrl: './mail-out.component.html'
})

export class MailOutComponent {
    @Input() prefillEmail: string;
    @Input() prefillSubject: string;
    @Input() prefillCC: string;
    @Input() prefillBCC: string;
    @Input() prefillReplyTo: string;
    displayDialog: boolean = false;
    message: string = '';


    public emailForm: FormGroup;
    constructor(private emailService: EmailService,
                private dialogRef: DynamicDialogRef,
                private translate: TranslateService,
                private router: Router,
                private route: ActivatedRoute,
                private location: Location) {
        this.emailForm = new FormGroup({
            email: new UntypedFormControl(null, [Validators.required, Validators.email]),
            subject: new UntypedFormControl(null, Validators.required),
            message: new UntypedFormControl(null),
            cc: new UntypedFormControl(null),
            bcc: new UntypedFormControl(null),
            replyTo: new UntypedFormControl(null),
        });
        this.route.paramMap.subscribe(params => {
            const prefillEmail = params.get('email');
            const prefillSubject = params.get('subject');
            const prefillCC = params.get('cc');
            const prefillBCC = params.get('bcc');
            const prefillReplyTo = params.get('replyTo');

            if (prefillEmail) {
                this.emailForm.get('email').setValue(prefillEmail);
            }
            if (prefillSubject) {
                this.emailForm.get('subject').setValue(prefillSubject);
            }
            if (prefillCC) {
                this.emailForm.get('cc').setValue(prefillCC);
            }
            if (prefillBCC) {
                this.emailForm.get('bcc').setValue(prefillBCC);
            }
            if (prefillBCC) {
                this.emailForm.get('replyTo').setValue(prefillReplyTo);
            }
        });
    }


    ngOnInit() {
        if (this.prefillEmail) {
            this.emailForm.get('email').setValue(this.prefillEmail);
        }
        if (this.prefillSubject) {
            this.emailForm.get('subject').setValue(this.prefillSubject);
        }
        if (this.prefillCC) {
            this.emailForm.get('cc').setValue(this.prefillCC);
        }
        if (this.prefillBCC) {
            this.emailForm.get('bcc').setValue(this.prefillBCC);
        }
        if (this.prefillReplyTo) {
            this.emailForm.get('replyTo').setValue(this.prefillReplyTo);
        }
    }
    protected validate(): boolean {
        return true;
    }


    submit() {
        const emailData = this.emailForm.getRawValue();
        // const subjectData = this.emailForm.getRawValue();
        console.log ("emailData:", emailData);

        this.emailService.sendEmail(emailData).subscribe(
            response => {
                this.message = 'Email sent successfully!';
                this.displayDialog = true;
                this.location.back();
                // setTimeout(() => {
                //     this.close();
                //     }, 300000);
            },
            error => {
                this.message = 'Error sending email. Please try again.';
                this.displayDialog = true;
            }
        );
        this.dialogRef.close();
    }

    close() {
        this.dialogRef.close();
        // this.location.back();
    }
}
