import { AfterViewInit, Component, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-map',
    template: '<div #mapContainer class="map-container"></div>',
    styleUrls: ['./map.component.css']
})
export class MapComponent implements AfterViewInit {
    @ViewChild('mapContainer', { static: false }) mapContainer!: ElementRef;
    map: google.maps.Map | undefined;

    constructor(private http: HttpClient) { }

    ngAfterViewInit() {
        console.log ("INIT MAP 1");
        this.initMap();
    }

    initMap() {
        console.log ("INIT MAP 2");
        const center = { lat: 41.594726, lng: 21.255308 };
        this.map = new google.maps.Map(this.mapContainer.nativeElement, {
            center,
            zoom: 12
        });
        this.placeMarker("Ilindenska 53, Star Dojran, Macedonia");
    }

    async placeMarker(address: string) {
        console.log ("PLACE MARKER");
        console.log (address);
        const apiKey = 'AIzaSyBq554sgC4AXqTPbNmQA44H1vkDyYXUO_k';
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`;
        const response: any = await this.http.get(url).toPromise();
        const location = response.results[0].geometry.location;
        new google.maps.Marker({
            position: location,
            map: this.map,
        });
    }
}
