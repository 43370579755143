<div class="layout-topbar mr-3">
    <div class="topbar-start">
        <button #menubutton type="button" class="topbar-menubutton p-link p-trigger" (click)="onMenuButtonClick()">
            <i class="pi pi-bars"></i>
        </button>

        <img [src]="'assets/muni/logo.png'" width="120" alt=""/>

        <!--    Select Langua-->
        <div class="language-dropdown ml-3 mt-2">
<!--            <p-dropdown [options]="languages"-->
<!--                        [(ngModel)]="selectedLanguage"-->
<!--                        optionLabel="label"-->
<!--                        (onChange)="changeLanguage($event.value.code)"-->
<!--                        appendTo="body">-->
            <p-dropdown [options]="languages"
                        [(ngModel)]="selectedLanguage"
                        optionLabel="label"
                        (onChange)="changeLanguage($event.value.code)"
                        appendTo="body">
                <ng-template let-language pTemplate="selectedItem">
                    <!-- <i class="pi pi-flag" [ngClass]="language.icon"></i>{{language.label}}-->
                    <!--                        <img [src]="language.icon" alt="{{language.label}} Flag" width="22" height="22">-->
                    {{language.label}}
                </ng-template>
                <ng-template let-language pTemplate="item">
                    <!--                        <i class="pi pi-flag" [ngClass]="language.icon"></i>{{language.label}}-->
                    <!--                        <img [src]="language.icon" alt="{{language.label}} Flag" width="22" height="22">-->
                    {{language.label}}
                </ng-template>
            </p-dropdown>
        </div>

<!--        <app-breadcrumb class="topbar-breadcrumb"></app-breadcrumb>-->
    </div>







    <div class="layout-topbar-menu-section">
        <app-sidebar></app-sidebar>
    </div>

    <div class="topbar-end">
        <ul class="topbar-menu  ">
<!--            <li>-->
<!--                <button pButton type="button" icon="pi pi-comment"-->
<!--                        class="p-button-text p-button-secondary text-color-secondary p-button-rounded flex-shrink-0"-->
<!--                        (click)="onChat()" *ngIf="authService.isAuthenticated" pTooltip="Chat" ></button>-->
<!--            </li>-->
<!--            <li class="ml-3">-->
<!--                <button pButton type="button" icon="pi pi-cog"-->
<!--                        class="p-button-text p-button-secondary text-color-secondary p-button-rounded flex-shrink-0"-->
<!--                        (click)="onConfigButtonClick()" pTooltip="Settings"></button>-->
<!--            </li>-->

            <li #profile class="profile-item topbar-item" style="margin-right:30px;">
                <span class="mr-5">{{authService.userDetails?.name }} {{authService.userDetails?.surname }}</span>
                <a pStyleClass="@next"
                   enterClass="ng-hidden"
                   enterActiveClass="px-scalein"
                   leaveToClass="ng-hidden"
                   leaveActiveClass="px-fadeout"
                   [hideOnOutsideClick]="true"
                   pRipple class="cursor-pointer">
                    <img class="border-circle" src="assets/muni/user-avatar.png">
                </a>

                <ul class="topbar-menu active-topbar-menu p-4 w-15rem z-5 ng-hidden border-round">
                    <li role="menuitem" class="m-0 mb-3" *ngIf="!authService.isAuthenticated">
                        <a href="/auth/login"
                           class="flex align-items-center hover:text-primary-500 transition-duration-200"
                           pStyleClass="@grandparent"
                           enterClass="ng-hidden"
                           enterActiveClass="px-scalein"
                           leaveToClass="ng-hidden"
                           leaveActiveClass="px-fadeout">
                            <i class="pi pi-fw pi-lock mr-2"></i>
                            <span>{{'login.login' | translate}}</span>
                        </a>
                    </li>
                    <li role="menuitem" class="m-0 mb-3" *ngIf="!authService.isAuthenticated">
                        <a [routerLink]="'/auth/register'"
                           class="flex align-items-center hover:text-primary-500 transition-duration-200"
                           pStyleClass="@grandparent"
                           enterClass="ng-hidden"
                           enterActiveClass="px-scalein"
                           leaveToClass="ng-hidden"
                           leaveActiveClass="px-fadeout">
                            <i class="pi pi-fw pi-cog mr-2"></i>
                            <span>{{'login.signUp' | translate}}</span>
                        </a>
                    </li>
                    <li role="menuitem" class="m-0 mb-3" *ngIf="authService.isAuthenticated">
                        <a href=""
                           (click)="navigateToEditUser();
                            $event.preventDefault()"
                            class="flex align-items-center hover:text-primary-500 transition-duration-200">
                            <i class="pi pi-fw pi-user mr-2"></i>
                            <span>{{'login.editProfile' | translate}}</span>
                        </a>
                    </li>
                    <li role="menuitem" class="m-0 mb-3" *ngIf="authService.isAuthenticated">
                        <a href=""
                           (click)="authService.logout()"
                           class="flex align-items-center hover:text-primary-500 transition-duration-200"
                           pStyleClass="@grandparent"
                           enterClass="ng-hidden"
                           enterActiveClass="px-scalein"
                           leaveToClass="ng-hidden"
                           leaveActiveClass="px-fadeout">
                            <i class="pi pi-fw pi-sign-out mr-2"></i>
                            <span>{{'login.logOut' | translate}}</span>
                        </a>
                    </li>

                    <li role="menuitem" class="custom-divider  m-0 mb-3 mt-3" *ngIf="authService.isJustUser()">
                    </li>
                    <li role="menuitem" class="m-0 mb-0" *ngIf="authService.isJustUser()">
                        <a href=""
                           (click)="navigateToDeleteUser(); $event.preventDefault()"
                           class="flex align-items-center hover:text-primary-500 transition-duration-200">
                            <i class="fa fa-trash mr-2"></i>
                            <span>{{'menu.request_delete' | translate}}</span>
                        </a>
                    </li>
                </ul>
            </li>

            <!--            <li class="right-panel-button relative hidden lg:block">-->
            <!--                <button pButton  type="button" label="Today" style="width:5.7rem" icon="pi pi-bookmark"-->
            <!--                class="layout-rightmenu-button hidden md:block font-normal" styleClass="font-normal" (click)="onSidebarButtonClick()"></button>-->
            <!--                <button pButton type="button" icon="pi pi-bookmark" styleClass="font-normal" class="layout-rightmenu-button block md:hidden" (click)="onSidebarButtonClick()"></button>-->
            <!--            </li>-->
        </ul>
    </div>
</div>
