import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';
import {LocationStrategy, NgClass, PathLocationStrategy} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AppLayoutModule} from './layout/app.layout.module';
import {TranslateLoader, TranslateModule, TranslateService, TranslateStore} from "@ngx-translate/core";
import {HTTP_INTERCEPTORS, HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {MessageService} from "primeng/api";
import {AuthService} from "./support/auth.service";
import {LayoutService} from "./layout/service/app.layout.service";
import {ToastModule} from "primeng/toast";
import {JWT_OPTIONS, JwtHelperService} from "@auth0/angular-jwt";
import {BasicAuthInterceptor} from "./support/interceptors/auth.interceptors";
import {appInitializerFactory} from "./support/initializer-function";
import {StorageService} from "./support/storage.service";

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        AppRoutingModule,
        AppLayoutModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        ToastModule,
        NgClass,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector, LayoutService, AuthService, StorageService],
            multi: true
        },
        {provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true},
        {provide: LocationStrategy, useClass: PathLocationStrategy}, TranslateService, TranslateStore, MessageService,
        AuthService, {provide: JWT_OPTIONS, useValue: JWT_OPTIONS}, JwtHelperService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
