import {NgModule} from '@angular/core';
import {EmailService} from '../../support/email.service';
import {CommonModule} from "@angular/common";
import {InputTextModule} from 'primeng/inputtext';
import {MailOutComponent} from './mail-out.component';
import {TranslateModule} from "@ngx-translate/core";
import {SharedModule} from "../generics/shared.module";
import { ButtonModule } from 'primeng/button';

@NgModule ({
    imports: [SharedModule, ButtonModule, TranslateModule, CommonModule,InputTextModule ],
    providers: [EmailService],
    declarations: [ MailOutComponent ],
    exports: [ MailOutComponent ]

})

export class MailOutModule {
}
