import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {Image} from "../../model/image";
import {environment} from "../../../environments/environment";

@Component({
    templateUrl:'image-gallery.component.html',
    selector: 'app-image-gallery',
    styles: [
        `.galleryBorder {
          border: 1px solid var(--gray-600);
          -moz-border-radius: 8px;
          -webkit-border-radius: 8px;
          height: 420px;
          align-content: center !important;
            vert-align: middle;
        }`],
    encapsulation: ViewEncapsulation.None
})
export class ImageGalleryComponent implements OnInit {
    @Input() all_images: any[] = [];
    currentIndex: number = 0;
    currentImage: string = '';

    @Input()
    form: FormGroup
    responsiveOptions: any;

    constructor() {
        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 5
            },
            {
                breakpoint: '768px',
                numVisible: 3
            },
            {
                breakpoint: '560px',
                numVisible: 1
            }
        ];
    }

    ngOnInit(): void {
        if (this.all_images.length > 0) {
            this.currentImage = this.all_images[0].imageUrl;
        }
    }


    prevImage() {
        if (this.currentIndex > 0) {
            this.currentIndex--;
        } else {
            this.currentIndex = this.all_images.length - 1;
        }
        this.currentImage = environment.API + this.all_images[this.currentIndex].imageUrl;
        console.log("->prevImage",  this.currentImage);
    }

    nextImage() {
        if (this.currentIndex < this.all_images.length - 1) {
            this.currentIndex++;
        } else {
            this.currentIndex = 0;
        }
        this.currentImage = environment.API + this.all_images[this.currentIndex].imageUrl;
        console.log("->nextImage",  this.currentImage);
    }

    get images() {
        return this.form.controls.images?.value?.length > 0 ? this.form.controls.images.value
            : [{
                imageUrl: 'assets/muni/no-img.png',
                thumbUrl: 'assets/muni/no-img-thumbnail.png',
                caption: 'No image available'
            } as Image]
    }

    getImage(item: Image) {
        return (item.caption === 'No image available' ? '' : environment.API) + item?.imageUrl;
    }

    getThumbnail(item: Image) {
        return (item.caption === 'No image available' ? '' : environment.API) + item?.thumbUrl;
    }

    get imagePadding(){
        return this.form?.controls.images?.value?.length > 0 ? '40px' : '110px';
    }
}
