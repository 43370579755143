import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Email} from "../model/email";  // Import your Email interface here
import {environment} from "../../environments/environment";

@Injectable()
export class EmailService {
    serviceUrl = '/komuniti-api/email'
    // private serviceUrl = `/${this.serviceUrl}/email`;

    constructor(private http: HttpClient) {}

    public sendEmail(email: Email): Observable<Email> {
        return this.http.post<string>(`${this.serviceUrl}`, email)
            .pipe(map(res => JSON.parse(res)));
    }
}
